@import "../../styles/base.scss";


.background {
  background-size: cover;
  height: 970px;
  background-image: url("../../images/main-bg.jpg");
  margin-bottom: 90px;
  @include breakpoint(xl) {
    height: auto;
  }
  @include breakpoint(medium) {
    margin-bottom: 50px;
  }
  .wrapper {
    width: 50%;
    margin-left: 50%;
    background-color: #0B4356;
    height: 100%;
    @include breakpoint(xl) {
      width: 100%;
      margin-left: 0;
      max-width: 100%;
      padding: 50px;
      height: auto;
    }

    h1 {
      width: 136%;
      color: white;
      padding-top: 90px;
      margin-left: -68%;
      font-size: 35px;
      font-weight: 300;
      letter-spacing: 2px;
      @include breakpoint(xxl) {
        font-size: 30px;
      }
      @include breakpoint(xl) {
        width: 100%;
        margin-left: 0;
        padding-top: 0px;
      }
    }

    > div {
      margin-top: 25px;
    }
  }
}
.cooperationSection .wrapper {
  max-width: 1200px;
  @include breakpoint(xl) {
    padding: 0 30px;
  }
}
.tourOperators {
  margin: 30px 0 53px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin: 60px 0;
    max-height: 75px;
  }
  @include breakpoint(xl) {
    flex-wrap: wrap;
    justify-content: center;
    img {
      margin: 60px 15px;
    }
  }
}

