@import "../../styles/base.scss";

header {
  .wrapper {
    display: flex;
    align-tiems: center;
    justify-content: space-between;
    min-height: 120px;
    max-width: 1265px;
    @include breakpoint(xl) {
      padding: 15px 30px;
    }
    @include breakpoint(small) {
      flex-wrap: wrap;
    }
  }

  a {
    color: #000;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .logo {
    margin: 0 30px;
    img {
      height: 60px;
    }
    @include breakpoint(small) {
      width: 100%;
      margin: 30px;
    }
  }

  .menu {
    display: flex;
    font-size: 14px;
    letter-spacing: 0.8px;
    align-items: center;
    text-transform: uppercase;
    font-family: $font-family-galano-grotesque;
    > div {
      display: flex;
    }

    img {
      margin: 0 10px 0 50px;
      height: 21px;
    }

    .email {
      text-decoration: underline;
    }

    @include breakpoint(medium) {
      flex-wrap: wrap;
      max-width: 320px;
      > div {
        width: 100%;
        margin: 15px 0;
      }
    }
    @include breakpoint(small) {
      padding-bottom: 30px;
    }
  }
}

