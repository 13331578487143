@import "../../styles/base.scss";

form {
  p {
    font-size: 22px;
    color: #fff;
    line-height: 32px;
    font-weight: 300;
  }
  .formDescription {
    position: absolute;
    top: 90px;
    left: 70px;
    max-width: 360px;
    @include breakpoint(xl) {
      left: 550px;
      max-width: 360px;
      top: 50px;
    }
    @include breakpoint(large) {
      position: relative;
      top: 0;
      left: 0;
      max-width: 100%;
      margin: 15px 0 30px 0;
    }
  }
  .alert {
    color: #fff;
    text-decoration: underline;
    font-size: 22px;
    font-weight: 400;
    display: block;
    margin-left: -68%;
    margin-bottom: 15px;
    @include breakpoint(xl) {
      margin-left: 0;
    }
  }
  textarea:focus, input:focus {
    outline: none;
  }
  textarea, input[type='text'], input[type='email'], input[type='tel']{
    font-size: 28px;
    color: #869499;
    border: 0;
    background-color: #fff;
    padding: 0 25px;
    box-sizing: border-box;
    border-radius: 23px;
    font-family: $font-family-silka;
    &::placeholder {
      color: #869499;
    }
    @include breakpoint(medium) {
      font-size: 22px;
    }
  }
  textarea {
    width: 136%;
    min-height: 195px;
    margin: 10px 0 10px -68%;
    line-height: 36px;
    filter: drop-shadow(0 0 0 #000);
    padding: 40px 5%;
    @include breakpoint(xl) {
      width: 100%;
      margin-left: 0;
    }
    @include breakpoint(medium) {
      min-height: 300px;
      padding: 5%;
    }
    &:required:valid {
      border: 5px solid green;
      + div {
        input[type='text'], input[type='email'], input[type='tel'] {
          &:required {
            border: 5px solid $orange;
          }
        }
      }
    }
  }
  .formWrapper {
    display: flex;
    margin-left: -68%;
    width: 136%;
    justify-content: space-between;
    @include breakpoint(xl) {
      width: 100%;
      margin-left: 0;
    }
    @include breakpoint(medium) {
      flex-wrap: wrap;
    }
    input[type='text'], input[type='email'], input[type='tel'] {
      width: 32.5%;
      height: 105px;
      line-height: 70px;
      text-align: center;
      @include breakpoint(medium) {
        width: 100%;
        margin: 10px 0;
      }
      &:required:valid {
        border: 5px solid green !important;
      }
    }
  }
  .formAgreements {
    text-align: left;
    font-size: 16px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: calc(-68% + 30px);
    width: calc(136% - 30px);
    text-indent: -28px;
    a {
      text-decoration: underline;
      color: #fff;
    }
    @include breakpoint(xl) {
      margin: 30px;
      width: 100%;
    }
  }
  input[type='checkbox']{
    background-color: transparent;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    border: 1px solid $orange;
  }
  button {
    height: 80px;
    width: 260px;
    font-family: $font-family-galano-grotesque;
    text-transform: uppercase;
    font-size:  16px;
    letter-spacing: 1px;
    border-radius: 39px;
    border: none;
    cursor: pointer;
    margin-left: -130px;
    @include breakpoint(xl) {
      margin-left: 0;
    }
  }
  .thankYouMessage {
    position: absolute;
    top: 80px;
    left: 50px;
    p {
      font-size: 30px;
      line-height: 50px;
      margin: 40px auto;
      max-width: 800px;
    }
    a {
      display: block;
      line-height: 80px;
      text-align: center;
      margin: 36px auto;
      width: 260px;
      height: 80px;
      font-family: $font-family-galano-grotesque;
      text-transform: uppercase;
      padding: 0 30px;
      font-size:  16px;
      letter-spacing: 1px;
      border-radius: 39px;
      border: none;
      cursor: pointer;
    }
    @include breakpoint(xl) {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}


