@import "../../styles/base.scss";

footer {
  .contactUsSection {
    background-color: $yellow;
    padding: 60px 0 35px 0;

    @include breakpoint(large) {
      padding-top: 40px;
      padding-bottom: 0;
    }

    .wrapper {
      margin: auto;
      @include breakpoint(xl) {
        padding: 0 30px 10px 30px;
      }
    }

    .contactUsElements {
      display: flex;
      margin: 60px 0;
      align-items: center;
      justify-content: space-between;

      > div {
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        @include breakpoint(xl) {
          height: 70px;
        }
        .writeToUs {
          min-width: 95px;
          text-align: center;
        }

        .address {
          min-width: 185px;
        }

        .hours {
          min-width: 80px;
        }

        img {
          margin-right: 30px;
          height: 21px;
        }
      }

      @include breakpoint(large) {
        flex-wrap: wrap;
        justify-content: center;
        > div {
          width: 30%;
          margin: 15px;
        }
      }
      @include breakpoint(small) {
        justify-content: flex-start;
        > div {
          width: 100%;
          justify-content: flex-start;
        }
      }

    }

    a {
      color: #000;
      text-decoration: underline;
    }
  }

  .footerElements {
    padding: 40px 0;
    font-size: 17px;

    @include breakpoint(medium) {
      padding: 0;
    }

    .wrapper {
      display: flex;
      margin: 0 auto;
      max-width: 1300px;
      justify-content: space-between;

      .footerMenu {
        display: flex;
        div {
          margin: 0 50px;
          p {
            margin: 15px 0;
          }
        }
        @include breakpoint(medium) {
          margin-left: 10px;
          div {
            margin: 0 10px;
          }
        }
      }
      .grantsForInnovationImage {
          width: 745px;
        img {
          width: 100%;
        }
      }

      @include breakpoint(medium) {
        flex-wrap: wrap;
        padding: 0 5%;
        div, .grantsForInnovationImage {
          width: 100%;
          margin: 15px 0;
        }
      }
    }

    a {
      color: #000;
      line-height: 36px;
      &.underline {
        text-decoration: underline;
      }
    }
  }
}
.qtravelInfo p {
  margin: 15px 0;
}
.qtravelInfo a {
  text-decoration: underline;
  color: #000;
  font-weight: bold;
}
