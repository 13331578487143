@import "mixins";

@font-face {
  font-family: "Sharp Grotesk Medium";
  src: url("../fonts/sharp-grotesk/sharpgrotesk-medium20.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url("../fonts/galano-grotesque/galano-grotesque-regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$orange: #FF7427;
$yellow: #FFBB12;
$font-family-silka: "Silka";
$font-family-sharp-grotesk: "Sharp Grotesk Medium";
$font-family-galano-grotesque: "Galano Grotesque";

body {
  font-family: $font-family-silka;
  font-size: 14px;
}
h1 {
  font-size: 34px;
  color: #000;
  padding-top: 45px;
  padding-bottom: 10px;
  letter-spacing: 1.8px;
  line-height: 40px;
  font-family: $font-family-sharp-grotesk;
}
h2 {
  font-size: 40px;
  font-family: $font-family-sharp-grotesk;
  font-weight: 300;
  letter-spacing: 1.8px;
  @include breakpoint(small) {
    font-size: 30px;
  }
}
h3 {
  font-family: $font-family-sharp-grotesk;
  font-size: 21px;
  color: #000;
  margin-top: 35px;
  margin-bottom: 15px;
}
p {
  line-height: 28px;
}
.wrapper {
  max-width: 1200px;
  margin: auto;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page {
  margin: 80px auto;
  max-width: 1200px;
  font-size: 1.375rem;
  line-height: 37px;
  p {
    font-size: 1.375rem;
    line-height: 37px;
  }
  .ueProjects a {
    color: #38A5DF;
    text-decoration: underline;
  }
  img {
    margin: 30px 0;
  }
}

input {
  width: 315px;
  height: 70px;
  line-height: 70px;

}
a {
  cursor: pointer;
  text-decoration: none;
}
ol {
  padding-left: 20px;
  margin: 15px 0;
}
ol li {
  margin: 10px;
}
.bold {
  font-weight: bold;
}
.centerText {
  text-align: center;
}
.euGrantsSection {
  margin: 15px 0;
  p {
    line-height: 45px;
  }
}
.contactPage {
  h3 {
    font-size: 28px;
  }
}
