@mixin breakpoint($point) {
  @if $point == small {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $point == large {
    @media (max-width: 1250px) {
      @content;
    }
  } @else if $point == xxl {
    @media (max-width: 1700px) {
      @content;
    }
  } @else if $point == xl {
    @media (max-width: 1400px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 861px) {
      @content;
    }
  }
}
