* {
  padding: 0;
  margin: 0;
}

body,
html {
  width: 100%;
  height: 100%;
}
